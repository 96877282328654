import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { OnChangeType } from "@interfaces";

interface propsAmountInput {
  value?: number;
  onChange: (event: {
    target: { value: string | number };
  }) => void;
}

const AmountInput = ({onChange}: propsAmountInput) => {
  const classes = useStyles();

  return (
    <div className={classes.inputBox}>
      <NumberFormat
        className="input"
        // getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: Number(values.value),
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        inputMode="numeric"
        allowNegative={false}
        allowEmptyFormatting={true}
        prefix="$"
      />
    </div>
  );
};

export default AmountInput;

const useStyles = makeStyles<Theme>((theme) => ({
  inputBox: {
    lineHeight: "inherit",
    color: "#393e44",
    background: "transparent",
    border: "1px solid #008296",
    padding: "1rem",
    borderRadius: "1.3rem",
    fontSize: "2rem",
    width: "100%",
    textAlign: "center",
    display: "flex",
    "& .input": {
      background: "transparent",
      textAlign: "center",
      width: "100%",
    },
    "& .input::placeholder": {
      color: "#393e44"
    }
  }
}));
