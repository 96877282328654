import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./AddMovement.style";
import { useAuth } from "@hooks";
import { BirthdateInput, TextInput } from "@components/Inputs";
import {
  ButtonOutlined,
  ButtonPrimary,
  ButtonText,
  NumericInput,
} from "@components/Reusables";
import _, { delay } from "lodash";
import {
  createManualTransaction,
  getFinerioSubcategories,
  getFinerioTransactions,
} from "@apollo";
import AmountInput from "@components/Reusables/AmountInput";
import { useApolloClient } from "@apollo/client";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { DatePicker } from "@material-ui/pickers";

interface LayoutProps {
  className?: string;
  textButton?: string;
}

export const AddMovement: React.FC<LayoutProps> = ({
  className,
  textButton,
}) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { finerioSubcategories } = getFinerioSubcategories();
  const { refetchFinerioTransactions } = getFinerioTransactions();
  const [isSelect, setSelect] = useState<string>("expense");
  const classes = useStyles({ isSelect: isSelect });
  const [isCheck, setCheck] = useState(false);
  const [categorySelect, setCategorySelect] = useState<string>("all");
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<string>();
  const client = useApolloClient();

  const [amount, setAmount] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());
  const [description, setDescription] = useState<string>();

  const subcategoriesGrouped = _.groupBy(finerioSubcategories, function (item) {
    return item.FinerioCategory.name;
  });

  useEffect(() => {
    if (isSelect === "income") {
      setCategorySelect("Transferencias");
      setSelectedSubcategoryId("000-0012-0002");
    } else {
      setCategorySelect("all");
    }
  }, [isSelect]);

  const handleNewTransaction = () => {
    const isCharge = isSelect === "expense";

    if (amount && selectedSubcategoryId && description && date) {
      createManualTransaction(client, {
        // bankId: bankId, add bankid when ready
        amount: amount,
        description: description,
        date: date.toISOString().split("T")[0],
        isCharge: isCharge,
        subcategoryId: selectedSubcategoryId,
      });
      delay(
        function (content) {
          refetchFinerioTransactions();
        },
        1000,
        "ready to refetch"
      );
      navigate(Route.finance + "#habits");
    } else {
      console.log("incomplete params");
    }
  };

  return (
    <div className={`${classes.container} ${className || ""} `}>
      <section className={classes.nameSpent}>
        <div className={classes.tab}>
          <Button
            className={classes.buttonTabExpense}
            onClick={() => setSelect("expense")}
          >
            <h2>GASTO</h2>
          </Button>
          <Button
            className={classes.buttonTabIncome}
            onClick={() => setSelect("income")}
          >
            <h2>INGRESO</h2>
          </Button>
        </div>
        <AmountInput
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <DatePicker
          className={`${classes.inputDate} ${className}`}
          name="date"
          label={"Fecha"}
          value={date}
          onChange={(e) => setDate(e)}
          inputVariant="outlined"
          format="dd/MM/yyyy"
          openTo="date"
          cancelLabel="Cancelar"
        />
        <TextInput
          className="name"
          label="Nombre o descripción"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </section>
      {/* TODO: RECURRING EXPENSE ON ADD MOVEMENTS */}
      {/* <div>
        <Checkbox
          onChange={() => {
            setCheck(!isCheck);
          }}
        />
        <span className="uppercase">Gasto recurrente</span>
      </div>
      {isCheck && (
        <section className={classes.isRepeated}>
          <div>
            <div className="column">
              <span>* Se repite</span>
              <TextInput label="Nombre" onChange={() => {}} />
            </div>
            <NumericInput label="" onChange={() => {}} name={""} />
            <div className="column">
              <span>Enlace de pago</span>
              <TextInput label="" onChange={() => {}} />
            </div>
            <div>
              <Checkbox onChange={() => {}} />
              <span className="uppercase">Agregar al calendario</span>
            </div>
          </div>
        </section>
      )} */}
      <div className={classes.separator}></div>
      <section className={classes.category}>
        <div>
          <h2 className="uppercase">CATEGORÍA</h2>
          {categorySelect === "all" ? (
            <div className={classes.categories}>
              {Object.entries(subcategoriesGrouped).map((category) => {
                return (
                  <div
                    onClick={() => {
                      setCategorySelect(category[1][0].FinerioCategory.name);
                    }}
                    className={classes.item}
                    key={category[1][0].FinerioCategory.id}
                  >
                    <img src={category[1][0].FinerioCategory.icon} />
                    <span>{category[1][0].FinerioCategory.name}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <section className={` ${classes.flex}`}>
              <div className={classes.categoryOnSubcat}>
                <img
                  src={
                    subcategoriesGrouped[categorySelect][0].FinerioCategory.icon
                  }
                />
                <span>
                  {subcategoriesGrouped[categorySelect][0].FinerioCategory.name}
                </span>
                <ButtonText
                  className={classes.button}
                  text="Ver más categorías"
                  onClick={() => {
                    setCategorySelect("all");
                  }}
                />
              </div>
              <div>
                <span className="uppercase">subcategoría</span>
                <div className={classes.subcategoriesDiv}>
                  {subcategoriesGrouped[categorySelect].map((category) => (
                    <div
                      onClick={() => {
                        setSelectedSubcategoryId(category.subcategoryId);
                      }}
                      key={category.subcategoryId}
                      className={classes.itemCategory}
                    >
                      {category.subcategoryId === selectedSubcategoryId ? (
                        <img src="https://rokinapp-images.s3.amazonaws.com/myHabits/subcategory/livingPalace/Action+_+check+circle+(1).svg" />
                      ) : (
                        <img src={category.icon} />
                      )}
                      <div>{category.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
        </div>
        <div className={classes.buttons}>
          <ButtonPrimary
            className="first"
            text={
              textButton || isSelect === "income"
                ? "Agregar ingreso"
                : "Agregar gasto"
            }
            noDegraded
            onClick={() => handleNewTransaction()}
          />
          <ButtonOutlined
            className="secondary"
            text="Cancelar"
            onClick={() => navigate(Route.finance)}
          />
        </div>
      </section>
    </div>
  );
};
