import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<
  Theme,
  {
    isSelect: string;
  }
>((theme) => ({
  container: {
    padding: "3rem 5rem 6rem",
    boxShadow: "4px 4px 15px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: 30,
    color: theme.palette.text.primary,
    fontFamily: "Montserrat",
    display: "flex",
    flexDirection: "initial",
    gap: "1rem",
    [theme.breakpoints.down(1240)]: {
      padding: "2rem 2rem 2rem",
      borderRadius: "2rem 2rem 0 0",
      flexDirection: "column"
    },
    "& .uppercase": {
      textTransform: "uppercase",
      fontSize: "0.75rem",
      fontWeight: 500,
    },
  },
  nameSpent: {
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
    "& .calendar": {
      width: "100%"
    },
    "& .name": {
      width: "100%",
    }
  },
  isRepeated: {
    "& >div": {
      display: "flex",
      alignItems: "end",
      gap: "2rem",
      [theme.breakpoints.down(1240)]: {
        flexWrap: "wrap",
        gap: "1rem",
      },
    },
    [theme.breakpoints.down(1240)]: {
      "& .name": {
        width: "15rem",
      },
      "& .number": {
        width: "7rem",
      },
      "& .calendar": {
        width: "7rem",
      },
      width: "15rem",
      margin: "0 auto",
    },
    "& .column": {
      flexDirection: "column",
      display: "flex",
      "& span": {
        textTransform: "uppercase",
        fontSize: "0.75rem",
        fontWeight: 500,
        marginBottom: "0.5rem",
        display: "block",
      },
    },
    "& >span": {
      marginBottom: "1rem",
      display: "block",
      textTransform: "uppercase",
      fontSize: "0.75rem",
      fontWeight: 500,
    },
  },
  category: {
    margin: "2rem 0",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    [theme.breakpoints.up(1240)]: {
      marginTop: 0,
    },
  },
  categorys: {
    display: "flex",
    flexWrap: "wrap",
    width: "51rem",
    gap: "1rem",
    [theme.breakpoints.down(1240)]: {
      width: "21rem",
      marginTop: "1rem",
    },
  },
  flex: {
    display: "flex",
    [theme.breakpoints.down(1240)]: {
      display: "grid",
    },
  },
  item: {
    width: 101,
    textAlign: "center",
    "& img": {
      margin: "0 auto",
    },
  },
  buttons: {
    width: "100%",
    display: "flex",
    gap: "2rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      width: "21rem",
      margin: "0 auto",
    },
    "& .first": {
      width: "20.9rem",
    },
    "& .secondary": {
      width: "20.9rem",
    },
  },
  categorySubcategory: {},
  buttons1: {
    width: "21rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    gap: "2rem",
    marginBottom: "2rem",
  },
  select: {
    opacity: "1!important",
    width: "5rem",
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  unselect: {
    cursor: "pointer",
  },
  itemCategory: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    padding: "0.3rem 0",
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    "& img": {
      width: "1rem",
      height: "1rem",
    },
  },
  button: {
    fontSize: "0.7rem",
    padding: 0,
    paddingRight: "2rem",
    fontWeight: 300,
  },
  categories: {
    marginTop: "2rem",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "1rem",
    [theme.breakpoints.down(1240)]: {
      width: "21rem",
    },
  },
  categoryOnSubcat: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& img": {
      width: 96,
      height: 96,
    },
    "& span": {
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
      fontWeight: 200,
    }
  },
  subcategoriesDiv: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  tab: {
    display: "flex",
    marginBottom: "2rem",
    gap: "2rem",
  },
  buttonTabIncome: {
    backgroundImage: ({ isSelect }) =>
      isSelect === "income" ? "linear-gradient(85deg, #0A5669 6%, #0A5669 94%)" : "",
    border: ({ isSelect }) =>
      isSelect === "income" ? "" : "2px solid",
    height: "2.5rem",
    width: "100%",
    color: ({ isSelect }) =>
      isSelect === "income" ? "#FFFFFF" : "#0A5669",
    fontFamily: "Montserrat",
    fontWeight: 500,
    borderRadius: "10px",
    textTransform: "inherit",
  },
  buttonTabExpense: {
    backgroundImage: ({ isSelect }) =>
      isSelect === "expense" ? "linear-gradient(85deg, #0A5669 6%, #0A5669 94%)" : "",
    border: ({ isSelect }) =>
      isSelect === "expense" ? "" : "2px solid",
    height: "2.5rem",
    width: "100%",
    color: ({ isSelect }) =>
      isSelect === "expense" ? "#FFFFFF" : "#0A5669",
    fontFamily: "Montserrat",
    fontWeight: 500,
    borderRadius: "10px",
    textTransform: "inherit",
  },
  separator: {
    margin: "0.5rem",
    borderLeft: "0.1rem solid #D6D6D6",
    height: "inherit",
  }
}));
